import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import CoAuthors from "../components/co-authors";
import SEO from "../components/seo"
import { Card, Row, Col, Button } from "react-bootstrap";

export default function PaperDetail({ data }) {
  const post = data.markdownRemark
  return (
    <Layout>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Row  className="mb-3"  md={12}>
          <Col>
            <Button variant="outline-primary" onClick={e => navigate(`/research`)}>Go Back</Button>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <Card border="light">
              <Card.Body>
                <Card.Title>
                  <h2>{post.frontmatter.title}</h2>
                </Card.Title>
                <Card.Subtitle>
                  {post.frontmatter.summary}
                </Card.Subtitle>                
              </Card.Body>
              <div dangerouslySetInnerHTML={{ __html: post.frontmatter.desc }}></div>
            </Card>
          </Col>
          <Col md={2}>
            {post.frontmatter.coauthor !== null ? <CoAuthors node={post.frontmatter.coauthor}></CoAuthors> : <></>}
          </Col>
        </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {        
        title
        summary
        desc
        coauthor
        layout
      }
      rawMarkdownBody
      excerpt
    }
  }
`